<template>
    <div v-if="filteredByProfileType.length" class="request-list">
        <RequestBox
            v-for="req in filteredByProfileType"
            :key="req.id"
            :request="req"
            :event="req.event"
            :is-incoming="true"
        />
        <div class="padding-bottom-box"></div>
    </div>
    <div v-else class="request-list-empty">
        <img src="@/assets/mirrored_egg.svg" />
        <LoadingEllipsis v-if="$apollo.queries.myEventRequests.loading">
            <p :style="{ display: 'inline-block' }" class="font-weight-medium">Fetching requests</p>
        </LoadingEllipsis>
        <p v-else class="font-weight-medium">You have no incoming requests</p>
    </div>
</template>

<script>
import RequestBox from './RequestBox.vue'
import { MY_EVENTREQUESTS } from '@/graphql/queries/eventRequest.js'

export default {
    name: 'IncomingRequests',
    components: {
        RequestBox
    },
    inject: ['getMyCurrentProfile', 'getCurrentProfileType'],
    apollo: {
        myEventRequests: {
            query: MY_EVENTREQUESTS,
            update({ myeventRequests }) {
                return myeventRequests.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
            },
            fetchPolicy: 'network-only'
        }
    },
    data() {
        return {
            myEventRequests: []
        }
    },
    computed: {
        filteredByProfileType() {
            return this.myEventRequests.filter((er) => {
                const profile = er.artist || er.venue
                return profile.id === this.getMyCurrentProfile().id
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.request-list {
    padding: 1rem;
    display: grid;
    align-content: start;
    row-gap: 1rem;
    height: 100%;
    padding-bottom: 110px;
    overflow: auto;
    .padding-bottom-box {
        height: 6rem;
    }
}

.request-list-empty {
    display: grid;
    place-content: center;
    justify-items: center;
    flex: 1;
    // height: 100%;
    padding: 0.5rem;
    row-gap: 0.5rem;
    // padding-bottom: 120px;
}
</style>
